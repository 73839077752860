
import { OBJ_KEY } from "@/utils/cookies";
import { removeCookie } from "@/utils/cookies";
import { addCookie } from "@/utils/cookies";
import { getCookie } from "@/utils/cookies";
import { defineStore } from "pinia";

export const piniaStore = defineStore("main", {
  //导出 pinia仓库
  state: () => {
    //相当于全局的 data()
    return {
      projectName: '',
      osType: 5,
      userType: 0,
      token: getCookie(OBJ_KEY['token']),
      username: getCookie(OBJ_KEY['username'])
      // userInfo: window.localStorage.getItem(OBJ_KEY['userInfo'])
    };
  },
  getters: {
    isLogin(state): boolean {
      return state.token !== ''
    },
  }, //相当于全局的computed
  actions: {
    setToken(token: string) {
      this.token = token
      addCookie(OBJ_KEY['token'], token, 1)
    },
    removeToken() {
      this.token = ''
      removeCookie(OBJ_KEY['token'])
      removeCookie(OBJ_KEY['username'])
    },
    setUsername(name:string){
      this.username = name
      addCookie(OBJ_KEY['username'], name, 1)
    },
    async getUserInfo() {
      // const res = await searchUserInfoApi({}) as any
      // if (res.status === 200) {
      //   this.userInfo = res.data
      //   window.localStorage.setItem(OBJ_KEY['userInfo'], JSON.stringify(res.data))
      // }
    }
  }
});
