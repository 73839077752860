import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'
import { piniaStore } from '@/store'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      {
        path: 'videoLib',
        name: 'videoLib',
        component: () => import('../views/videoLib/index.vue')
      },
      {
        path: 'videoCate',
        name: 'videoCate',
        component: () => import('../views/videoCate/index.vue')
      },
      {
        path: 'uploadVideo',
        name: 'uploadVideo',
        component: () => import('../views/uploadVideo/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('../views/login/index.vue'),
    children: []
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      title: '登录'
    },
    component: () => import('../views/shareVideo/index.vue'),
    children: []
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {


  const store = piniaStore()
  if (to.path === '/share') {
    next()
  }

  if (!store.$state.token && to.path !== '/login') {
    next({ path: '/login' })
  } else {
    next()
  }







})

export default router
