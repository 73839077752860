import Cookies from 'js-cookie'

export const OBJ_KEY = {
  'token': 'star_token',
  'username': 'star_username'
}

/**
 * 获取当前的顶级域名
 * @returns String
 */
function getDomain() {
  if (window.location.hostname !== 'localhost') {
    const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/
    const res = re.test(document.domain) ? document.domain : `.${document.domain
      .split('.')
      .slice(-2)
      .join('.')}`
    return res
  } else {
    return document.domain
      .split('.')
      .slice(-2)
      .join('.')
  }
}

export const addCookie = (name: string, value: any, day: any) => {
  Cookies.set(name, value, {
    expires: day,
    domain: getDomain()
  })
}

export function getCookie(name: string) {
  let value = unescape(Cookies.get(name))
  if (!value || value === 'undefined') {
    value = ''
  }
  return value
}

export function removeCookie(name: string) {
 
  //   //获取当前时间
  //   var date = new Date();

  //   //将date设置为过去的时间
  //   date.setTime(date.getTime() - 10000);

  //   //将name这个cookie删除
  //   document.cookie = name+ "=111; expires=" + date.toGMTString();
  Cookies.remove(name, {
    domain: getDomain()
  })
}



export const setToken = (key: string, token: string, cookieExpires: any = 1) => {
  Cookies.set(key, token, { expires: cookieExpires })
  // localStorage.setItem(key, token)
}

export const getToken = (key: string) => {
  const token = Cookies.get(key)
  if (token) return token
  else return ''
  // const token = localStorage.getItem(key)
  // if (token) return token
  // else return ''
}

export const delToken = (key: string) => {
  Cookies.remove(key)
}